@use "../../vendor/nice-select2/scss/nice-select2" with (
  $font_size: 16px,
  $input_height: 46px,
  $input_border_radius: 2px,
  $dropdown_padding: 20px,
  $gray_light: #c9c9c9,
  $gray: #000,
);

select.nice-select-init:not(.nice-select-hidden) {
  height: 46px;
}
select.nice-select-init.wide {
  width: 100%;
}

.nice-select-wrap {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

select.nice-select-hidden {
  // По-умолчанию не сбрасывается. Создает лишние отступы.
  border: none !important;
}

.nice-select {
  &:after {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    height: 8px;
    width: 8px;
    margin-top: -6px;
    right: 20px;
  }

  .nice-select-dropdown {
    border: 1px solid #c9c9c9;
    box-shadow: 0 6px 14px rgba(#000, 0.08);
  }

  &.wide {
    .nice-select-dropdown {
      left: -1px !important;
      right: -1px !important;
    }
  }

  .list {
    margin: 0;
    padding: 4px 0;
  }

  .option.selected {
    font-weight: 400;
    color: $primary;
  }
}
