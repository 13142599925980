.slider-text {

  &__content p {
    font-size: 21px;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      font-size: 19px;
      line-height: 28px;
    }
  }

  .slick-arrow {
    top: -75px;

    @include media-breakpoint-down(lg) {
      top: -57px;
    }
  }
}
