.breadcrumbs {
  padding-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &__link {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: currentColor;
    }
  }

  &__item {
    padding-bottom: 5px;

    &:after {
      margin: 0 9px;
      display: inline-block;
      width: 6px;
      height: 6px;
      content: '';
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      transform-origin: center center;
    }

    &:last-child {
      color: $gray-dark;

      &:after {
        display: none;
      }

      .breadcrumbs__link {
        pointer-events: none;
      }
    }

    &:first-child {
      padding-right: 10px;
      line-height: 17px;

      &:after {
        display: none;
      }

      .breadcrumbs__link {
        width: 19px;
        height: 17px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAARCAYAAAA/mJfHAAABaUlEQVQ4T+2UPU4CURDHf7MSaUygN3HhAmBigzZyAsIB1oCNlsIJXE8gltqwROyNJ8BGtyERLgBrYg+JjUZ2zFskkQ+JWlg55Xz83ryZ/3vCEuuVcylrJEeEFLG4Dlf0LO35/a9KZFGg5+TyllolRMsmrnArsBvlqnihhI1002/N1k7BHvdyZVRKCnlgCFyHMXVNN1GXb+ICRSAh0FJVz77yGxOo9Mr5pPX2UkKpIKSAAMQLY6u1tNcazJ4+zn+tQNS1jdJHqIWxeEMCZ9sUJATtGOfGpe8tm+Pn2PgmVBTJAgMDU+DMbt5XvguZzQuc7Rpw9AGTE7t5Z+bxKwucHRf0+O9h2dO6GcWcdar7kRp+1NkHLBCIlqMQbfLXMCPcbnXf6I/Mab1lRPwP+1pnZgHfnln0aNG5X2CihcLWobs2ehlkhk8PxtdNrG8+r8STN+3zSOiC5M3nMHlOS5Vf2DpYGL9pX0z53wFNa/aCvWuK/gAAAABJRU5ErkJggg==)
                    no-repeat center center;
      }
    }
  }

  &--white {
    color: #fff;

    .breadcrumbs__item:first-child {

      .breadcrumbs__link {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAQCAYAAAD0xERiAAAA40lEQVQ4T+WT0VECQRBEX0eAIWAEkoFmICGYAUcEkgFHBpgBZCAZQAQaAkTQ1lzNXcF5IMePH8zXbs3M29nuXfFH2H4AXoG1pP2lcp1LJmQCFMAOGAFzYHEO2gmz/Qa8J6SQ9G17CJTAEzCT9NEe5ARm+yVPP2TDZ7sha2bAAJhKamoqmO36Co8JWV6hZUwf0K+EbpVXivFLSZHsFbajJ3QtAlYBbgHVpzaMf4Gl8M8tDTZhQO/JsiHcrt2r1iHPrbBG22N57hQWLv36Oi3nQvCIYwPqfeQ28WjjK417Pfvu4tUPPCOpAB+YRSUAAAAASUVORK5CYII=);
      }
    }

    .breadcrumbs__item:last-child {
      opacity: .7;
      color: #fff;
    }
  }
}
