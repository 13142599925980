.flag {
  display: inline-block;
  width: 1.33em;
  height: 1em;
  background-size: contain;
  background-position: center;
  border: 1px solid #bababa;
  position: relative;
  top: 0.125em;
}
.flag--lg {
  font-size: 1.25em;
}
.flag--ru {
  background-image: url("./img/ru.svg");
}
.flag--kz {
  background-image: url("./img/kz.svg");
}
.flag--uz {
  background-image: url("./img/uz.svg");
}
