.vjs-theme-fantasy {
  --vjs-theme-fantasy--primary: #e15c39;

  width: 100%;

  .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .vjs-play-control {
    width: 3em;

    .vjs-icon-placeholder{
      &::before {
        border: none;
        margin-top: 0.37em;
      }
    }

    &:hover {
      .vjs-icon-placeholder{
        &::before {
          border: none;
        }
      }
    }
  }

  .vjs-live-control {
    align-items: center;
    margin-left: 1em;
  }

  .vjs-play-progress:before {
    background-color: var(--vjs-theme-fantasy--secondary);
  }

  .vjs-volume-vertical {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.vjs-theme-fantasy.vjs-audio {
  height: 54px;

  .vjs-big-play-button {
    display: none;
  }
}
