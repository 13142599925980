.slider-team {

  &__item {
    position: relative;
  }

  .slick-arrow {
    top: 73px;

    @include media-breakpoint-down(md) {
      top: 47px;
    }

    @include media-breakpoint-down(xs) {
      top: 31px;
    }
  }

  .slick-prev {
    right: 125px;

    @include media-breakpoint-down(lg) {
      right: 60px;
    }

    @include media-breakpoint-down(xs) {
      right: 55px;
    }
  }

  .slick-next {
    right: 80px;

    @include media-breakpoint-down(lg) {
      right: 15px;
    }

    @include media-breakpoint-down(xs) {
      right: 10px;
    }
  }
}
