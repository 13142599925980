.stripe-card {
  background: #fff url(../../images/form-block-bg.png) 0 0 repeat-y;
  border-radius: 4px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, .08);
  padding-left: 15px;

  @include media-breakpoint-down(sm) {
    background: #fff url(../../images/form-block-bg-sm.png) 0 0 repeat-y;
    padding-left: 8px;
  }
}
.stripe-card--primary {
  background-image: url(../../images/form-block-orange.png);
}
.stripe-card__body--p--md {
  padding: 32px 40px 40px;

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
}
