.slider-privilege {
  margin-bottom: 50px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  .slick-track {
    display: flex !important;
  }

  &__item {
    margin: 10px 10px 20px;
    padding: 10px 20px 40px;
    height: auto;
    min-height: 226px;
    text-decoration: none;
    box-shadow: 0 6px 14px rgba(0, 0, 0, .08);

    @include transition;

    &:hover {
      color: #fff;
      background: $primary;

      @include media-breakpoint-down(sm) {
        color: #000;
        background: #fff;
      }
    }

    @include media-breakpoint-down(sm) {
      min-height: 175px;
    }
  }
}
