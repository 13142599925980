@font-face {
  font-display: swap;
  font-family: 'Clear Sans';
  src: local('Clear Sans Light'), local('ClearSans-Light'),
       url('../../fonts/clearsanslight.woff2') format('woff2'),
       url('../../fonts/clearsanslight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Clear Sans';
  src: local('Clear Sans'), local('ClearSans'),
       url('../../fonts/clearsans.woff2') format('woff2'),
       url('../../fonts/clearsans.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Clear Sans';
  src: local('Clear Sans Bold'), local('ClearSans-Bold'),
       url('../../fonts/clearsansbold.woff2') format('woff2'),
       url('../../fonts/clearsansbold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Sans';
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
       url('../../fonts/ibmplexsanssemibold.woff2') format('woff2'),
       url('../../fonts/ibmplexsanssemibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
