.privilege {

  &__title {
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 21px;
      font-weight: 700;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }

  &__text {

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 19px;
    }
  }
}
