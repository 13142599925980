.icon {
  display: inline-block;
  position: relative;
  --size: 1em;
  width: var(--size);
  height: var(--size);
  top: calc(var(--size) * 0.25);
  background-position: center;
  background-size: contain;
}
.icon--120 {
  --size: 1.2em;
}
.icon--call-center-orange {
  background-image: url(../../images/icon-call-center-orange.svg);
}
