.text-block {

  p {
    margin-bottom: 20px;
  }

  // .list - класс, применяемый nice-select
  ul:not(.invoice-list):not(.list) {
    margin: 0 0 34px;
    padding: 0 0 0 30px;
    list-style: none;

    li {
      font-size: 18px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }

      &:before {
        margin-left: -1em;
        display: inline-block;
        width: 1em;
        font-size: 20px;
        font-weight: bold;
        content: '\2022';
        color: $primary;
      }
    }
  }
}
