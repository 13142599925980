.ya-share2 {

  &__list {
    display: flex;
    align-items: center;
  }

  &__container ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__badge {
    display: block;
    width: 30px;
    height: 30px;
    background-color: transparent;
  }

  &__icon {
    display: block;
    width: 30px;
    height: 30px;
    fill: #e15c39;
  }

  &__title {
    display: none;
  }

  &__link {
    margin-right: 7px;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $primary;
    background-color: transparent;
    border: 1px solid $light;
    border-radius: 50%;

    @include transition;

    &:hover {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }

    @include media-breakpoint-down(md) {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 10px;
    }
  }

  &__item_service {

    &_facebook .ya-share2__badge .ya-share2__icon {
      background: url(../../images/facebook-orange.svg) center no-repeat;

      &:hover {
        background: url(../../images/facebook-white.svg) center no-repeat;
      }

      @include media-breakpoint-down(md) {
        background: url(../../images/facebook-white.svg) center no-repeat;
      }
    }

    &_vkontakte .ya-share2__badge .ya-share2__icon {
      background: url(../../images/vk-orange.svg) center no-repeat;

      &:hover {
        background: url(../../images/vk-white.svg) center no-repeat;
      }

      @include media-breakpoint-down(md) {
        background: url(../../images/vk-white.svg) center no-repeat;
      }
    }

    &_odnoklassniki .ya-share2__badge .ya-share2__icon {
      background: url(../../images/odnoklassniki-orange.svg) center no-repeat;

      &:hover {
        background: url(../../images/odnoklassniki-white.svg) center no-repeat;
      }

      @include media-breakpoint-down(md) {
        background: url(../../images/odnoklassniki-white.svg) center no-repeat;
      }
    }

    &_twitter .ya-share2__badge .ya-share2__icon {
      background: url(../../images/twitter-orange.svg) center no-repeat;

      &:hover {
        background: url(../../images/twitter-white.svg) center no-repeat;
      }

      @include media-breakpoint-down(md) {
        background: url(../../images/twitter-white.svg) center no-repeat;
      }
    }

    &_whatsapp .ya-share2__badge .ya-share2__icon {
      background: url(../../images/whatsapp-orange.svg) center no-repeat;

      &:hover {
        background: url(../../images/whatsapp-white.svg) center no-repeat;
      }

      @include media-breakpoint-down(md) {
        background: url(../../images/whatsapp-white.svg) center no-repeat;
      }
    }

    &_viber .ya-share2__badge .ya-share2__icon {
      background: url(../../images/viber-orange.svg) center no-repeat;

      &:hover {
        background: url(../../images/viber-white.svg) center no-repeat;
      }

      @include media-breakpoint-down(md) {
        background: url(../../images/viber-white.svg) center no-repeat;
      }
    }
    &_telegram .ya-share2__badge .ya-share2__icon {
      background: url(../../images/telegram-orange.svg) 6px center / 16px 16px no-repeat;

      &:hover {
        background: url(../../images/telegram-white.svg) 6px center / 16px 16px no-repeat;
      }

      @include media-breakpoint-down(md) {
        background: url(../../images/telegram-white.svg) 6px center / 16px 16px no-repeat;
      }
    }
  }
}

.share {
  margin-bottom: 35px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__list {
    margin-left: 15px;
    display: flex;

    @include media-breakpoint-down(sm) {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  &__link {
    margin-right: 7px;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $primary;
    border: 1px solid $light;
    border-radius: 50%;

    @include transition;

    svg {
      display: block;
    }

    &:hover {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }

    @include media-breakpoint-down(md) {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 10px;
    }
  }
}
