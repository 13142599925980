.pane-list {
  padding: 18px 20px;
  height: 100%;

  @include media-breakpoint-down(xs) {
    padding: 18px 10px 40px;
  }

  &__title {
    position: relative;
    margin-bottom: 15px;
    padding-left: 41px;
    padding-bottom: 21px;
    font-size: 14px;
    line-height: 24px;
    border-bottom: 1px solid #e9e9e9;

    &:before {
      position: absolute;
      top: 5px;
      left: 8px;
      display: block;
      width: 14px;
      height: 18px;
      content: '';
      background: url('../../images/pane-title.svg') no-repeat 0 0 transparent;
    }

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }

  &__ul {
    padding-left: 0;
    list-style: none;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 36px;

    &:before {
      margin-left: 5px;
      display: block;
      width: 0;
      height: 20px;
      flex-shrink: 0;
      content: '';
      background: url(../../images/arrow-right.svg) no-repeat left center;
      background-size: contain;

      @include transition;
    }

    &:hover {

      &:before {
        width: 40px;
      }
    }

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  .btn {

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
