.labeled-display {
  display: flex;
  flex-flow: column;
  font-size: 1rem;
  line-height: 1.5rem;
}
.labeled-display__label {
  color: rgba(33, 37, 41, 0.40);
}
.labeled-display__value {
  color: #212529;
  font-weight: bold;
}
