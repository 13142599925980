.hover-hint {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: super;
}

.hover-hint__icon {
  display: block;
  width: 16px;
  height: 16px;
  color: transparent;
  font-size: 1px;
  line-height: 1px;
  user-select: none;
  background: url("../../images/hint-icon.svg") center / contain no-repeat;
}

.hover-hint__dropdown {
  // Цепляется не к .hover-hint, а к первому предку с position: relative;
  // Это позволяет контролировать расположение дропдауна.
  position: absolute;
  top: 32px;  // Фолбэк, если не поддерживается lh
  top: calc(1lh + 12px);
  left: 0;
  right: 0;
  display: none;
  background: white;
  border: 1px solid $gray;
  border-radius: 2px;
  padding: 16px 16px 24px;
  box-shadow: 0 6px 14px rgba(#000, 0.08);
  z-index: 100;
  max-width: 420px;

  font-size: 14px;
  line-height: 18px;

  p, ul, ol {
    font-size: inherit;
    line-height: inherit;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  li:not(:last-child) {
    margin-bottom: 8px;
  }

  ul {
    padding: 0 0 0 16px;
    list-style: none;

    li {
      position: relative;

      &:before {
        content: '\2022';
        position: absolute;
        display: block;
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
        color: $primary;
        top: 0;
        left: -12px;
      }
    }
  }
}

.hover-hint:hover {
  .hover-hint__dropdown {
    display: block;
  }
}
.hover-hint__icon:focus ~ .hover-hint__dropdown {
  display: block;
}
