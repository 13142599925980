.reviews {

  &__icon {
    margin-right: 20px;
    padding-top: 2px;
    width: 100%;
    max-width: 20px;
    flex-shrink: 0;

    @include media-breakpoint-down(md) {
      margin-right: 10px;
      padding-top: 4px;
    }
  }

  &__author {
    font-size: 16px;
    line-height: 24px;
    color: $link-color;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  &__head {
    margin-bottom: 8px;
    padding-bottom: 16px;
    display: flex;
    border-bottom: 1px solid#e9e9e9;

    @include media-breakpoint-down(md) {
      padding-bottom: 12px;
    }
  }

  &__btn {
    padding-left: 0;
    font-size: 18px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }
}
