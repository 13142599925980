.popup {
  padding: 0;
  border-radius: 4px;

  &.fancybox-content {
    max-width: 1200px;
  }

  .form-block {

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
    }

    .h1 {

      @include media-breakpoint-down(sm) {
        margin-bottom: 15px !important;
      }
    }
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 45px;
    width: 24px;
    height: 24px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABIUlEQVQ4T4WTTVKDQBBGv06oMjvjBRxzAuMOXOENdGuNVXgCy5vkBsYKcZ0byCqzFE9AkROwzAIZCwKEzF9YdvV7/fUMQzkPFgDeQFiwlXjHmW/34kdS0gckLdl6+0o5D2TPtEWbo4fbhsq7uCK12JlVidYHfLJYRFQ3npPY4JptBC6JCz4RmCQEJBIIB+s0sYfr9QmsSY7dGqwl6HpzHhQALoeTWCy0YUZB9nw/H43kj3oLBDxdx2JjqB9LLfwNYGr4F4qqoofZ1zY1noEKE+Tvn4fHcYmNBN22kCZp9jLDk3C2TIosCqfjcp/YJOSCu6guSf0W+hM/xD5MVs/AKPHkHe24n9bxXLAlSc5icUO1GeV+Dm+SmiabXmbG/bDr/wecXM03FwH0xAAAAABJRU5ErkJggg==)
                no-repeat center center;

    @include media-breakpoint-down(md) {
      top: 10px;
      right: 14px;
    }
  }

  &__close_sm {
    top: 15px;
    right: 15px;

    @include media-breakpoint-down(md) {
      top: 5px;
      right: 7px;
    }
  }
}

.popup__message {
  padding: 46px 60px 40px;
  width: auto;
  height: 150px;
}
