.toggle {
  position: relative;
  width: 52px;
  height: 32px;
  overflow: hidden;
  color: transparent;
  user-select: none;
  cursor: pointer;
  flex: 0 0 52px;

  input {
    @extend .sr-only;
  }
}

.toggle__body {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #c9c9c9;
  border: 1px solid $gray;
  border-radius: 16px;
  display: inline-block;
  margin: 0;
  transition: all .2s;
  overflow: hidden;
  box-shadow: inset 0 0 4px rgba(#000, 0.25);

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 0 4px rgba(#000, 0.4);
    transition: all .2s;
  }
}

input:checked ~ .toggle__body {
  background: $primary;
  border: 1px solid $primary;

  &::after {
    left: 20px;
  }
}
