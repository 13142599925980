.showcase {
  padding-top: 66px;
  background: url('../../images/show-1.png') no-repeat left bottom,
              url('../../images/show-2.svg') no-repeat right top;
  border-top: 1px solid $light;
  border-bottom: 1px solid $light;

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    border-top: 0;
    border-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    background: url('../../images/show-1-md.png') no-repeat left bottom,
                url('../../images/show-2-md.png') no-repeat right top;
  }

  @include media-breakpoint-down(xs) {
    background: url('../../images/show-1-xs.png') no-repeat -100px bottom,
                url('../../images/show-2-xs.png') no-repeat right top;
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__left {
    padding-left: 63px;
    width: 50%;

    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-bottom: 60px;
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 10px;
    }
  }

  &__right {
    margin-left: auto;
    display: flex;
    width: 40%;
    justify-content: center;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}
