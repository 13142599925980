.menu-mob {
  padding: 90px 0 20px;
  width: 100%;
  max-width: none;
  background-color: #fff;

  @include media-breakpoint-down(sm) {
    padding-top: 55px;
  }

  &__content {

    @include media-breakpoint-down(sm) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    .menu-mob__list {
      margin-top: 9px;
      padding-left: 20px;
      border-top: 1px solid $light;

      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }
    }

    .menu-mob__item:last-child {
      margin-bottom: 15px;
    }
  }

  &__link {
    font-family: $font-ibm-600;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;

    &--arrow {
      position: relative;
      padding-right: 30px;
      display: block;

      &:after {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
        display: block;
        width: 20px;
        height: 20px;
        content: '';
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAPCAYAAADUFP50AAAA60lEQVQ4T2NkwAV65i9gYGDYwFCSuAGbEkas+rrmGzAwMZ4HyzH+T2QoTgQZggKwawQpAdvIGI9LM26N6JoZ/gciOxu/RlTNHxj+/XdkKEu8APYBzsBBlkA4G66Z8X6CgwDTn5/xDAz/BfAZEqQaEHCWW8KA/d/fHx6f7gczPoyxamBg+F9PjM1WWlEMT9l4GUR/f/vBeD/BQoHpD2PDfwYGBXya0xTd1XfxK0iA1Dh9ethJrB8nMDAw5iNHDWGNvfMTGP4zzkePT/wacWjCHx14NOHW2D9fgeEv433y0mrvfFCu2IAtgYMMBADJA1+Tawka/QAAAABJRU5ErkJggg==)
                    no-repeat right center;
        transform-origin: center center;

        @include transition;
      }

      &.is-active {

        &:after {
          right: -4px;
          transform: rotate(90deg);
        }
      }
    }

    &--back {
      padding-right: 0;
      padding-left: 30px;
      display: none;

      @include media-breakpoint-down(sm) {
        display: inline-block;
      }

      &:after {
        right: 0;
        left: 0;
        transform: scale(-1, -1);
      }
    }
  }

  &__item {
    padding-top: 9px;
    padding-bottom: 9px;
    border-bottom: 1px solid $light;
  }

  &__drop {
    display: none;

    @include media-breakpoint-down(sm) {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      padding-top: 64px;
      padding-right: 10px;
      padding-left: 10px;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
  }

  &__link-all {
    margin-bottom: 15px;
    margin-left: 20px;
    display: inline-block;
    font-size: 14px;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }

  &__lang-button {
    background: none;
    border: none;
    font-size: 14px;
    line-height: 19px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  &__lang-button-text {
    text-decoration: underline;
  }
}
