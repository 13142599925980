.pane {
  position: relative;
  padding: 16px 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .08);

  &:hover {

    .pane__icon-right {
      opacity: 1;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }

  &--number {
    padding: 16px 20px 20px;
    height: 100%;

    @include media-breakpoint-down(sm) {
      padding: 15px;
    }
  }

  &--privilege {
    padding: 27px 20px;

    @include media-breakpoint-down(sm) {
      padding: 15px;
    }
  }

  &--privilege-sm {
    padding: 16px 20px;

    @include media-breakpoint-down(sm) {
      padding: 15px;
    }

    h4 {
      margin-bottom: 0 !important;
    }

    .pane__icon {
      margin-bottom: 11px;
      margin-left: 11px;
    }
  }

  &--contacts {
    padding: 26px 20px;

    @include media-breakpoint-down(md) {
      padding-right: 15px;
      padding-left: 15px;
    }

    @include media-breakpoint-down(sm) {
      padding: 15px;
    }

    h4 {
      margin-bottom: 0 !important;
      word-wrap: break-word;

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    .pane__icon {
      padding: 0 5px 22px;

      img {

        @include media-breakpoint-down(md) {
          max-width: 20px;
        }
      }
    }
  }

  &--btns {
    padding: 15px 15px 40px;

    h4,
    .h4 {
      //      margin-bottom: 24px !important;
      margin-bottom: 12px !important;
      padding-right: 30px;
    }

    h5,
    .h5 {
      margin-bottom: 27px;
    }
  }

  &__number {
    margin-bottom: 20px;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: $gray-dark;
    border: 1px solid $light;
    border-radius: 50%;

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
      width: 25px;
      height: 25px;
      font-size: 14px;
    }
  }

  &__icon-right {
    position: absolute;
    top: 10px;
    right: 0;
    opacity: 0;

    @include transition;

    img {
      width: 44px;
      height: 34px;

      @include media-breakpoint-down(sm) {
        width: 39px;
        height: 30px;
      }
    }
  }

  &__btns {
    margin-top: auto;

    .btn {

      @include media-breakpoint-down(lg) {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
  &__controls {
    display: flex;
    flex-flow: row wrap;
    gap: 1.25rem;
  }

  &__detail {
    margin-top: auto;

    .btn {

      @include media-breakpoint-down(lg) {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }

  h4,
  .h4,
  h5,
  .h5 {

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  .pane__body {
    flex-grow: 1;
  }
}

a {

  .pane {

    @include transition;

    &:hover {
      color: #fff;
      background-color: $primary;
    }
  }

  .pane--contacts {

    &:hover {
      color: #000;
      background-color: #fff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    }
  }
}
