.menu-pane {
  margin-right: -10px;
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;

  &__item {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    display: flex;
    width: calc(20% - 20px);
    height: 80px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 6px 14px rgba(0, 0, 0, .08);
    cursor: pointer;

    @include transition;

    &:hover {
      z-index: 1;
      color: #fff;
      background-color: $primary;
      box-shadow: 0 6px 14px rgba(225, 92, 57, .7);

      .menu-pane__hover {
        visibility: visible;
        opacity: 1;
        box-shadow: 0 6px 14px rgba(225, 92, 57, .7);

        @include transition;
      }
    }
  }

  &__title {
    padding: 0 20px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }

  &__hover {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    visibility: hidden;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    background-color: $primary;
    border-top: 1px solid #fff;
    border-radius: 0 2px 2px 0;
    box-shadow: 0 6px 14px rgba(0, 0, 0, .08);

    &:after {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1px;
      height: 100%;
      content: '';
      background-color: #fff;
    }

    &--nopsevdo {

      &:after {
        display: none;
      }
    }
  }

  &__link {
    width: 50%;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    color: #fff;

    @include transition;

    &:hover {
      color: $primary;
      background-color: #fff;
    }

    &--single {
      width: 100%;
    }
  }
}
