.front-inner {
  position: relative;
  z-index: 1;
  margin-top: -70px;
  padding-top: 70px;
  padding-bottom: 84px;
  min-height: 680px;

  @include media-breakpoint-down(lg) {
    min-height: 570px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 80px;
    padding-bottom: 60px;
    min-height: auto;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0;
    padding-top: 17px;
    padding-bottom: 116px;
    background-color: $primary;
  }

  &__bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  &__content {
    padding-top: 90px;
    padding-left: 65px;
    padding-right: 65px;
    max-width: 1050px;
    color: #fff;

    @include media-breakpoint-down(md) {
      padding-top: 30px;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 30px;
      max-width: 675px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .breadcrumbs {

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &--place {
    margin-top: 0;
    padding-top: 70px;
    min-height: 550px;

    @include media-breakpoint-down(lg) {
      min-height: 570px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 28px;
      padding-bottom: 60px;
      min-height: auto;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 45px;
    }

    .front-inner__content {
      padding-top: 0;
      max-width: 750px;

      @include media-breakpoint-down(md) {
        padding-left: 10px;
        padding-right: 10px;
        max-width: 535px;
      }
    }
  }

  &--man {
    @include media-breakpoint-down(sm) {
      background-color: $dark;
    }

    .front-inner__bg {
      background-color: $dark;
      background-repeat: no-repeat;
      background-position: right center;
      background-size: auto 100%;
    }
  }

  &--office {
    background-color: $dark;

    @include media-breakpoint-down(sm) {
      background-color: $dark;
      padding-bottom: 108px;
    }

    .front-inner__content {
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }
    }

    .front-inner__bg {
      left: 50%;
      background-repeat: no-repeat;
      background-position: 10% bottom;
      background-size: auto calc(100% - 60px);

      @include media-breakpoint-down(lg) {
        display: none !important;
      }

      @include media-breakpoint-down(sm) {
        display: block !important;
        left: 0;
        background-position: center bottom;
        background-size: 320px;
      }
    }

    .btn {
      margin-top: 15px;
    }
  }
}
