/* Custom variables */

$spacer: 10px;

$spacers: (
  0: 0,
     5: (
    $spacer * .5,
  ),
     10: $spacer,
      15: (
    $spacer * 1.5,
  ),
      20: (
    $spacer * 2,
  ),
      25: (
    $spacer * 2.5,
  ),
      30: (
    $spacer * 3,
  ),
      35: (
    $spacer * 3.5,
  ),
      40: (
    $spacer * 4,
  ),
      45: (
    $spacer * 4.5,
  ),
      50: (
    $spacer * 5,
  ),
      55: (
    $spacer * 5.5,
  ),
      60: (
    $spacer * 6,
  ),
      65: (
    $spacer * 6.5,
  ),
      70: (
    $spacer * 7,
  ),
      75: (
    $spacer * 7.5,
  ),
      80: (
    $spacer * 8,
  ),
      85: (
    $spacer * 8.5,
  ),
      90: (
    $spacer * 9,
  ),
      95: (
    $spacer * 9.5,
  ),
      100: (
    $spacer * 10,
  ),
);

$link-decoration: underline;

$link-color: #000;

$link-hover-decoration: none;

$line-height-sm: 1;

$line-height-lg: 2;

$transition-base: .3s cubic-bezier(.25, .1, .25, 1);

$headings-margin-bottom: $spacer;

$grid-gutter-width: 20px;
$grid-breakpoints: (
  xs: 0,
      sm: 576px,
      md: 768px,
      lg: 992px,
      xl: 1220px,
);
$container-max-widths: (
  sm: 540px,
      md: 758px,
      lg: 960px,
      xl: 1200px,
);

/* Project variables */
$primary: #e15c39;
$secondary: #6ad1e3;
$dark: #2f8f9f;
$light: #ebebeb;
$gray: #c9c9c9;
$gray-dark: #8d8d8d;
$text-muted: $gray-dark;

$fonts-clear-sans: 'Clear Sans', 'Arial', sans-serif;
$font-ibm-600: 'IBM Plex Sans', 'Tahoma', sans-serif;
$font-family-sans-serif: $fonts-clear-sans;

$dropdown-border-radius: 0;
$dropdown-link-active-color: #212529;
$dropdown-link-active-bg: #f0f0f2;

/* Imports */
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/custom-forms';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/print';
@import '../../node_modules/bootstrap/scss/dropdown';
