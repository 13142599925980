.slider-order {
  position: relative;
  z-index: 5;
  margin-bottom: 130px;
  min-height: 355px;
  width: 380px;
  border-radius: 2px;

  @include media-breakpoint-down(md) {
    margin-bottom: 90px;
  }

  @include media-breakpoint-down(xs) {
    width: 280px;
  }

  &__item {
    background: $white;
    border-radius: 2px;
  }

  &__body {
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0 6px 14px rgba(0, 0, 0, .08);
  }

  &__back {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: -20px;
    width: calc(100% + 40px);
    height: 355px;
    background: $white;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
    transform: translateY(-50%);

    @include media-breakpoint-down(xs) {
      left: -10px;
      width: calc(100% + 20px);
    }
  }
}
