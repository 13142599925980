.slider-reviews {
  margin: 0 -10px;

  .slick-arrow {
    top: -60px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0;
  }

  &--arrows-center {
    padding: 0 90px;

    @include media-breakpoint-down(sm) {
      padding: 0 0 40px;
    }

    .slick-arrow {
      top: 0;
      bottom: 0;
      margin: auto;

      @include media-breakpoint-down(sm) {
        top: auto;
      }
    }

    .slick-prev {
      right: auto;
      left: 20px;

      @include media-breakpoint-down(sm) {
        left: calc(50% - 40px);
      }
    }

    .slick-next {
      right: 20px;

      @include media-breakpoint-down(sm) {
        right: calc(50% - 40px);
      }
    }
  }

  &__item {
    padding: 0 10px;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
}
