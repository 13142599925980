.slider-gallery {
  border: 1px solid $light;
  border-radius: 4px;

  &__item {
    padding: 20px;
  }

  &__img-wrap {
    margin-bottom: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img {

    @include media-breakpoint-down(md) {
      max-width: 190px;
    }
  }

  &__name {
    margin-bottom: 0;
    font-size: 16px;
    color: #000000;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &__nav {
    display: none;
  }

  .slick-dots {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    border-top: 1px solid $light;

    li {
      margin: 0;
      padding: 5px 15px;
      display: flex;
      width: 16.66%;
      height: 88px;
      justify-content: center;
      align-items: center;
      transition: all .3s ease;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        padding: 5px 10px;
        height: 60px;
      }

      &:hover {
        background-color: $light;
      }
    }

    .slick-active {
      background-color: $light;
    }

    img {
      max-height: 98%;
    }
  }

  &--contacts {

    .slider-gallery__item {

      @include media-breakpoint-down(sm) {
        padding: 75px 20px 20px;
      }
    }
  }
}
