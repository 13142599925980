.slick-arrow {
  position: absolute;
  z-index: 1;
  top: -57px;
  display: block;
  overflow: hidden;
  width: 35px;
  height: 35px;
  font-size: 0;
  text-indent: -9999px;
  color: transparent;

  &:not(.slick-disabled) {

    &:hover {
      background: url('../../images/slick-hover.svg') no-repeat center $primary;
      border: none;
      border-radius: 50%;
    }
  }
}

.slick-next {
  right: 10px;
  background: url('../../images/slick-arrow.svg') no-repeat center $white;
  border: 1px solid $light;
  border-radius: 50%;
}

.slick-prev {
  right: 55px;
  background: url('../../images/slick-arrow.svg') no-repeat center $white;
  border: 1px solid $light;
  border-radius: 50%;
  transform: rotate(180deg);
}

.slick-disabled {
  opacity: .4;

  &:hover {
    cursor: default;
  }
}

.slick-slide {

  &:focus {
    outline: none;
  }
}

.slider-order {

  .slick-arrow {
    top: auto;
    bottom: -100px;

    @include media-breakpoint-down(md) {
      bottom: -60px;
    }
  }

  .slick-prev {
    right: auto;
    left: 150px;

    @include media-breakpoint-down(xs) {
      left: 100px;
    }
  }

  .slick-next {
    right: 150px;

    @include media-breakpoint-down(xs) {
      right: 100px;
    }
  }
}

.slick-dots {
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;

  li {
    margin: 0 10px;

    &.slick-active {

      button {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }

  button {
    display: block;
    width: 10px;
    height: 10px;
    text-indent: -9999px;
    background-color: $light;
    border: 1px solid $light;
    border-radius: 50%;
    transition: all .3s ease;

    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.select2-container {
  width: 100% !important;

  &--default {

    .select2-selection--single {
      border: 1px solid $gray;
      border-radius: 2px;

      .select2-selection__rendered {
        padding-right: 40px;
        font-size: 14px;
        color: $gray-dark;
      }

      .select2-selection__arrow {
        right: 23px;
        width: 13px;
        height: 38px;
        background: transparent url(../../images/select-arrow.png) center center no-repeat;
        background-size: 13px 8px;

        b {
          display: none;
        }
      }
    }
  }

  &--open {

    .select2-selection--single {

      .select2-selection__arrow {
        transform: rotate(180deg);
      }
    }

    .select2-selection__rendered {
      color: #000 !important;
    }
  }

  .select2-selection {

    &:focus {
      outline: none;
    }

    &--single {
      display: flex;
      height: 38px;
      align-items: center;
    }

    &__rendered {
      width: 100%;
    }
  }
}

.select2-results__option {
  font-size: 14px;
}

.select2-dropdown {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}

//fancybox
.fancybox {

  &--img {
    z-index: 99999;

    .fancybox-inner {
      margin: 20px 50px;
      background: #fff;
      border-radius: 4px;

      @include media-breakpoint-down(md) {
        margin-right: 15px;
        margin-left: 15px;
      }

      @include media-breakpoint-down(sm) {
        margin: 10px;
      }
    }

    .fancybox-toolbar {
      visibility: visible !important;
      opacity: 1 !important;
    }

    .fancybox-image {
      height: auto !important;
    }

    .fancybox-caption {
      padding: 0 100px 30px;
      text-align: left;
      background: none;

      @include media-breakpoint-down(sm) {
        padding: 0 15px 10px;
      }

      &__body {
        font-size: 18px;
        color: #000000;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }
    }

    .fancybox-content {}

    .fancybox-button--zoom {
      display: none !important;
    }

    .fancybox-button--close {
      top: 15px;
      right: 45px;
      padding: 0;
      width: 32px;
      height: 32px;
      background: transparent url(../../images/fancybox-close-icon.svg) center center no-repeat;
      background-size: 16px 16px;

      @include media-breakpoint-down(md) {
        top: 0;
        right: 0;
      }

      svg {
        display: none;
      }
    }
  }
}

.fancybox-is-open .fancybox-bg {
  opacity: .45;
}

.fancybox--menu {

  .fancybox-close-small {
    display: none !important;
  }

  .fancybox-slide {
    padding: 0;
    display: block;

    &:before {
      display: none;
    }
  }
}

.fancybox--filters {

  .fancybox-slide {
    top: 50px;

    @include media-breakpoint-down(md) {
      top: 0;
    }
  }

  .fancybox-content {
    padding: 30px 15px 50px;
  }
}

.popup,
.filters {

  .fancybox-close-small {
    display: none !important;
  }
}

.fancybox--text {

  .fancybox-content {
    padding: 65px 60px 45px;
    max-width: 1200px;

    @include media-breakpoint-down(md) {
      padding: 40px 30px 35px;
    }

    @include media-breakpoint-down(sm) {
      padding: 30px;
    }

    @include media-breakpoint-down(xs) {
      padding: 30px 15px;
    }
  }

  .fancybox-close-small {
    top: 15px;
    right: 35px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABIUlEQVQ4T4WTTVKDQBBGv06oMjvjBRxzAuMOXOENdGuNVXgCy5vkBsYKcZ0byCqzFE9AkROwzAIZCwKEzF9YdvV7/fUMQzkPFgDeQFiwlXjHmW/34kdS0gckLdl6+0o5D2TPtEWbo4fbhsq7uCK12JlVidYHfLJYRFQ3npPY4JptBC6JCz4RmCQEJBIIB+s0sYfr9QmsSY7dGqwl6HpzHhQALoeTWCy0YUZB9nw/H43kj3oLBDxdx2JjqB9LLfwNYGr4F4qqoofZ1zY1noEKE+Tvn4fHcYmNBN22kCZp9jLDk3C2TIosCqfjcp/YJOSCu6guSf0W+hM/xD5MVs/AKPHkHe24n9bxXLAlSc5icUO1GeV+Dm+SmiabXmbG/bDr/wecXM03FwH0xAAAAABJRU5ErkJggg==) no-repeat center center;

    @include media-breakpoint-down(md) {
      top: 0;
      right: 0;
    }

    @include media-breakpoint-down(sm) {
      top: 0;
      right: 0;
    }

    svg {
      display: none;
    }
  }
}

.btn--zoom {
  position: absolute;
  top: 12px;
  right: 20px;
  width: 16px;
  height: 16px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABR0lEQVQ4T6WSQU6DQBSG/4do3bUewEw4gXYnXbUnsGtD0vYG3kB6gx6BRupaTyBuGHdyA+QGdGdMM88MlDqlkoY4qwc8vvf/7x+CcVLvZmixNQHxGEAPQA6mZ0Vq6YTvkdlb1VQVmecGACZ/NRXvmAKximf17wVg/2eaK6hIT0zvBteWhTHAD00QKmSDXnWDUtR3nuKkPqUE8UfRAx6Zduh3Os1FGPtNFjJv4G+VLEUop7sdZJ6bA+g2Ta8aDaW5COWFCWD9IEK5W2izCvegV1toq2AtQqkjLk77HTBexErqe1IC/p3C4T1g3yKKLh/lWxmfugWoTIfxqU47fSeItO1SQVUcvYlApncNIFF2Z1RB9ja/taMz1h67ANZgRIp4Afs8Odl8RQy6MiFHozMjTafDXh3SCqBhJoSIZ60BFcTafN8r+2zxA9DeswaoOZqbAAAAAElFTkSuQmCC) no-repeat center;
  background-size: contain;
}

/* Custom Scrollbar */

.mCSB_draggerRail {
  height: 8px !important;
  background: #ebebeb !important;
  border-radius: 4px !important;
}

.mCSB_dragger_bar {
  margin: 7px auto !important;
  height: 8px !important;
  background: $primary !important;
  border-radius: 4px !important;
}

.mCSB_scrollTools {
  margin: 0 auto !important;
  width: calc(100% - 20px) !important;

  @include media-breakpoint-down(md) {
    width: calc(100% - 10px) !important;
  }
}
