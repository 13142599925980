.stepper {
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid $gray;
  border-radius: 2px;
}
.stepper__button {
  background: none;
  border: none;
  width: 48px;
  height: 44px;
  position: relative;
  color: transparent;

  &:disabled {
    opacity: 0.4;
  }

  @media (hover: hover) {
    &:hover {
      background: #f0f0f0;
    }
  }
}
.stepper__button--minus, .stepper__button--plus {
  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.stepper__button--minus {
  &::after {
    background: url("../../images/plain-minus-black.svg") center / contain no-repeat;
  }
}
.stepper__button--plus {
  &::after {
    background: url("../../images/plain-plus-black.svg") center / contain no-repeat;
  }
}
.stepper__input {
  flex: 1 0 50px;
  min-width: 50px;
  text-align: center;
  background: none;
  border: none;
  font-size: 16px;
  line-height: 16px;
}
