.search-result {

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }

  td {
    padding-bottom: 15px;
    vertical-align: top;
  }

  &__num {
    color: $gray-dark;
  }

  &__link {
    font-weight: 700;
  }
}
