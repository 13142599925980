.invoice-list {
  display: flex;
  flex-flow: column;
  gap: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.invoice-list__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  gap: 4px;
  font-size: 16px;
  line-height: 21px;

  &::after {
    content: '';
    display: block;
    order: 2;
    flex-grow: 1;
    height: 0;
    border-bottom: 1px dashed rgba(#000, 0.4);
    position: relative;
    bottom: 3px;
    min-width: 24px;
  }
}
.invoice-list__item--total {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}
.invoice-list__item-label {
  order: 1;
}
.invoice-list__item-value {
  order: 3;
}
.invoice-list__footnote {
  color: rgba(#212529, 0.6);
}
