.card {
  position: relative;
  padding-bottom: 35px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .08);

  &.align-self-start {
    padding-top: 20px;
  }

  &__link {
    display: block;
    text-decoration: none;
  }

  &__img-wrapper {
    margin-bottom: 20px;
    display: block;
    overflow: hidden;
  }

  &__img {
    display: block;
    padding-bottom: 52.5%;  // Соотношение сторон 1200:630
    position: relative;

    img {

      @supports (object-fit: cover) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__date {
    padding-right: 15px;
    padding-left: 15px;
    display: block;
    font-size: 16px;
    line-height: 21px;
    color: $gray-dark;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &__title {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    display: block;
    text-decoration: underline;

    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__text {
    margin-bottom: 0;
    padding-right: 15px;
    padding-left: 15px;
  }

  &__label {
    position: absolute;
    top: 10px;
    right: 0;
    padding-right: 10px;
    padding-left: 10px;
    height: 34px;
    font-size: 16px;
    line-height: 34px;
    color: #fff;
    background-color: $dark;
    border-radius: 100px 0 0 100px;
  }
}
