.tariff-calc {
  display: flex;
  flex-flow: row nowrap;
  gap: 80px;
}
.tariff-calc__column {
  flex: 1 1 0;
}

.tariff-calc__column-heading {
  margin-bottom: 32px;
}
.tariff-calc__sections {
  display: flex;
  flex-flow: column;
  gap: 32px;
}
.tariff-calc__subheading {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.tariff-calc__calc-form {
  display: flex;
  flex-flow: column;
  gap: 24px;
}

.tariff-calc__label-group {
  display: flex;
  flex-flow: column;
  gap: 2px;
}
.tariff-calc__label {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}
.tariff-calc__label-description {
  font-size: 14px;
  line-height: 20px;
  color: rgba(#212529, 0.4);
}

.tariff-calc__param-list {
  display: flex;
  flex-flow: column;
  gap: 24px;
}
.tariff-calc__param-field {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.tariff-calc__param {
  width: 160px;
  flex: 0 0 auto;
}

.tariff-calc__option-list {
  display: flex;
  flex-flow: column;
  gap: 24px;
}
.tariff-calc__option-field {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0 32px;
  position: relative;
}
.tariff-calc__option-toggle {
  margin-top: 5px;
}

@include media-breakpoint-down(lg) {
  .tariff-calc {
    flex-flow: column;
    gap: 52px;
  }
}

@include media-breakpoint-only(lg) {
  .tariff-calc__calc-form  {
    gap: 32px;
  }
  .tariff-calc__param-list {
    flex-flow: row wrap;
    gap: 40px;
  }
  .tariff-calc__param-field {
    flex: 0 0 calc(33% - #{40px * 2 / 3});
    max-width: calc(33% - #{40px * 2 / 3});
    flex-flow: column;
    gap: 12px;
    align-items: flex-start;
  }
  .tariff-calc__param {
    width: 100%;
  }

  .tariff-calc__option-list {
    flex-flow: row wrap;
    gap: 24px 48px;
  }
  .tariff-calc__option-field {
    flex: 0 0 calc(50% - #{48px / 2});
    max-width: calc(50% - #{48px / 2});
  }
}

@include media-breakpoint-down(sm) {
  .tariff-calc {
    gap: 40px;
  }
  .tariff-calc__column-heading {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 480px) {
  .tariff-calc__param-field {
    flex-flow: column;
    gap: 12px;
    align-items: flex-start;
  }
  .tariff-calc__param {
    width: 100%;
  }
}
