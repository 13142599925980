.header {
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid $light;

  @include transition;

  &__row {
    display: flex;
    height: 70px;
    align-items: center;

    @include media-breakpoint-down(sm) {
      height: 50px;
    }
  }

  &__logo {
    --logo-colored: url(../../images/syntonic__color_full.svg);
    --logo-white: url(../../images/syntonic__white.svg);

    display: block;
    width: 170px;
    height: 30px;
    background: var(--logo-colored) no-repeat left center;
  }

  &__logo--kk {
    --logo-colored: url(../../images/syntonic__color_full.kk.svg);
    --logo-white: url(../../images/syntonic__white.kk.svg);
  }

  &__logo--uz {
    --logo-colored: url(../../images/syntonic__color_full.uz.svg);
    --logo-white: url(../../images/syntonic__white.uz.svg);
  }

  &__links {
    margin-bottom: 0;
    padding-left: 36px;
    display: flex;
    list-style: none;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__links-item {
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &__btn-menu {
    position: relative;
    margin-left: 15px;
    width: 40px;
    height: 35px;
    background: transparent;

    @include media-breakpoint-down(lg) {
      margin-left: 30px;
    }

    @include media-breakpoint-down(sm) {
      margin-left: auto;
      width: 20px;
    }

    &--close {
      display: none;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABIUlEQVQ4T4WTTVKDQBBGv06oMjvjBRxzAuMOXOENdGuNVXgCy5vkBsYKcZ0byCqzFE9AkROwzAIZCwKEzF9YdvV7/fUMQzkPFgDeQFiwlXjHmW/34kdS0gckLdl6+0o5D2TPtEWbo4fbhsq7uCK12JlVidYHfLJYRFQ3npPY4JptBC6JCz4RmCQEJBIIB+s0sYfr9QmsSY7dGqwl6HpzHhQALoeTWCy0YUZB9nw/H43kj3oLBDxdx2JjqB9LLfwNYGr4F4qqoofZ1zY1noEKE+Tvn4fHcYmNBN22kCZp9jLDk3C2TIosCqfjcp/YJOSCu6guSf0W+hM/xD5MVs/AKPHkHe24n9bxXLAlSc5icUO1GeV+Dm+SmiabXmbG/bDr/wecXM03FwH0xAAAAABJRU5ErkJggg==) !important;
      background-repeat: no-repeat;
      background-position: center;
    }

    &--desktop {

      &:hover {

        .header__btn-menu-item {
          width: 20px;
        }
      }
    }
  }

  &__btn-menu-item {
    position: absolute;
    left: 10px;
    height: 4px;
    transition: all .3s linear;

    @include media-breakpoint-down(sm) {
      left: 0;
    }

    &:nth-child(1) {
      top: 9px;
      width: 20px;
      background: $primary;
    }

    &:nth-child(2) {
      top: 16px;
      width: 15px;
      background: $primary;
    }

    &:nth-child(3) {
      top: 23px;
      width: 10px;
      background: #008ca0;
    }
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }
  &__left {
    flex-grow: 1;
  }
  &__right {
    margin-left: auto;
  }

  &__phone {
    margin-right: 20px;
    font-size: 14px;
    text-decoration: none;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__lang-button {
    background: none;
    border: none;
    margin-left: 20px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .btn--call-me {
    margin-right: auto;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.header--home,
.header--white {
  position: relative;
  background-color: transparent;
  border-bottom: none;

  @include media-breakpoint-down(sm) {
    position: sticky;
    background-color: #fff;
    border-bottom: 1px solid $light;
  }

  .btn-link--calc {
    color: #fff;
    background-image: url(../../images/calc-white.png);
  }

  .btn--call-me {
    color: #fff;
    background-image: url(../../images/call-me.svg);

    &:hover {
      color: $primary;
      background-color: #fff;
    }
  }

  .header__phone {
    color: #fff;
  }

  &.is-sticky {
    position: sticky;
    background-color: #fff !important;
    border-bottom: 1px solid $light !important;

    .btn--call-me {
      color: $primary;
      background-image: url(../../images/call-me-color.svg);
      border: 1px solid;

      &:hover {
        color: #fff;
        background: url(../../images/call-me-color.svg) no-repeat left center;
        background-color: $primary;
        border-color: $primary;
      }
    }

    .btn-link--calc {
      color: #000;
      background-image: url(../../images/calc-color.png);
    }

    .header__link {
      color: #000;
    }
  }
}

.header--white {

  .header__logo {

    @include media-breakpoint-up(md) {
      background-image: var(--logo-white);
    }
  }

  .header__btn-menu-item {
    background: #fff;

    &:nth-child(1) {

      @include media-breakpoint-down(sm) {
        background: $primary;
      }
    }

    &:nth-child(2) {

      @include media-breakpoint-down(sm) {
        background: $primary;
      }
    }

    &:nth-child(3) {

      @include media-breakpoint-down(sm) {
        background: #008ca0;
      }
    }
  }

  .header__link {
    color: #fff;
  }

  &.is-sticky {

    .header__logo {
      background-image: var(--logo-colored);
    }

    .header__btn-menu-item {

      &:nth-child(1) {
        background: $primary;
      }

      &:nth-child(2) {
        background: $primary;
      }

      &:nth-child(3) {
        background: #008ca0;
      }
    }

    .header__link {
      color: #000;
    }
  }
}

//.fancybox-active {
.body--menu {

  .fancybox--menu {
    z-index: 999;
  }

  .header {

    @include media-breakpoint-down(lg) {
      z-index: 1000;
    }
  }

  .header__btn-menu {

    @include media-breakpoint-down(lg) {
      display: none;
    }

    &--close {
      display: block;
    }
  }

  .header--home,
  .header--white {

    .btn--call-me {

      @include media-breakpoint-down(lg) {
        color: $primary;
        background-image: url(../../images/call-me-color.svg);
        border: 1px solid;
      }

      &:hover {

        @include media-breakpoint-down(lg) {
          color: #fff;
          background: url(../../images/call-me-color.svg) no-repeat left center;
          background-color: $primary;
          border-color: $primary;
        }
      }
    }

    .btn-link--calc {

      @include media-breakpoint-down(lg) {
        color: #000;
        background-image: url(../../images/calc-color.png);
      }
    }
  }

  .header--white {

    .header__logo {

      @include media-breakpoint-down(lg) {
        background-image: var(--logo-colored);
      }
    }
  }
}
