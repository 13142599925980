.footer {
  padding-top: 36px;
  background-color: $dark;

  @include media-breakpoint-down(md) {
    padding-top: 23px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 15px;
  }

  &__logo {
    img {
      width: auto;
      height: 36px;
    }
  }

  &__title {
    text-decoration: none;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &__menu {
    margin-bottom: 40px;
    padding-left: 0;
    list-style: none;

    li {
      //      padding-top: 3px;
      //      padding-bottom: 3px;
      padding: 0;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
    color: $white;

    @include transition();

    &:hover {
      text-decoration: none;
      color: $white;
    }

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }

  &__bottom {
    display: flex;
    min-height: 86px;
    align-items: center;
    background-color: #277886;

    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__row {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      position: relative;
      padding-top: 15px;
      padding-bottom: 17px;
      display: block;
    }
  }

  &__search {
    position: relative;
    min-width: 280px;

    @include media-breakpoint-down(md) {
      min-width: 270px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }

  &__search-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 100%;
    background: url('../../images/search-btn.svg') no-repeat left center transparent;
  }

  &__socials {
    margin-right: auto;
    margin-left: 122px;

    @include media-breakpoint-down(md) {
      margin-left: 29px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
      margin-left: 0;
      text-align: center;
    }
  }

  &__copyright {
    margin-left: 76px;
    font-size: 14px;
    color: $white;

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }

    @include media-breakpoint-down(sm) {
      position: absolute;
      right: 0;
      bottom: 17px;
    }
  }

  &__extra-buttons {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 10px;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;

    @include media-breakpoint-down(lg) {
      flex-flow: row-reverse nowrap;
      gap: 20px;
    }

    @include media-breakpoint-down(sm) {
      flex-flow: column nowrap;
      gap: 16px;
    }
  }

  &__go-top {
    display: block;
    width: 35px;
    height: 35px;
    background: url('../../images/go-top-white.svg') no-repeat center transparent;
    border: 1px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;

    @include transition();

    &:hover {
      opacity: .7;
    }
  }

  &__lang-button {
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    background: none;

    &:hover {
      opacity: .7;
    }
  }
}
