.front-news {
  max-width: 1400px;

  &__banner-wrapper {
    padding-bottom: 52.5%;  // Соотношение сторон 1200:630
    position: relative;
  }

  &__banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media-breakpoint-down(xs) {
      &--large {
        display: none;
      }
    }

    @include media-breakpoint-up(sm) {
      &--small {
        display: none;
      }
    }
  }


  &__content {
    margin-top: 40px;

    @include media-breakpoint-down(sm) {
      margin-top: 32px;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 24px;
    }
  }

  &__label {
    display: flex;
    width: 119px;
    height: 34px;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: $dark;
    border-radius: 100px 0 0 100px;

    @include media-breakpoint-down(md) {
      width: 107px;
      height: 30px;
      font-size: 14px;
    }
  }

  &__text {
    font-size: 16px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }
}
