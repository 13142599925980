.filters {
  padding: 15px 24px 40px 30px;
  border-radius: 2px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, .08);

  &__title {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;

    &--bordered {
      border-top: 1px solid $light;
    }

    &:after {
      right: 0;
    }

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__h4 {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    border-top: 1px solid $light;

    &:after {
      right: 0;
    }

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 21px;
      font-weight: 700;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 14px;
    width: 16px;
    height: 16px;
    background-color: transparent;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABIUlEQVQ4T4WTTVKDQBBGv06oMjvjBRxzAuMOXOENdGuNVXgCy5vkBsYKcZ0byCqzFE9AkROwzAIZCwKEzF9YdvV7/fUMQzkPFgDeQFiwlXjHmW/34kdS0gckLdl6+0o5D2TPtEWbo4fbhsq7uCK12JlVidYHfLJYRFQ3npPY4JptBC6JCz4RmCQEJBIIB+s0sYfr9QmsSY7dGqwl6HpzHhQALoeTWCy0YUZB9nw/H43kj3oLBDxdx2JjqB9LLfwNYGr4F4qqoofZ1zY1noEKE+Tvn4fHcYmNBN22kCZp9jLDk3C2TIosCqfjcp/YJOSCu6guSf0W+hM/xD5MVs/AKPHkHe24n9bxXLAlSc5icUO1GeV+Dm+SmiabXmbG/bDr/wecXM03FwH0xAAAAABJRU5ErkJggg==) !important;
  }

  &__btns {
    padding-top: 25px;

    @include media-breakpoint-down(sm) {
      display: flex;
    }

    .btn {

      @include media-breakpoint-only(lg) {
        font-size: 14px;
      }

      @include media-breakpoint-only(md) {
        margin-bottom: 10px;
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        padding: 10px 20px;
        flex-grow: 1;
        font-size: 16px;
        line-height: 24px;
        border-radius: 2px;
      }

      @include media-breakpoint-down(xs) {
        padding: 10px 12px;
      }

      & + .btn {

        @include media-breakpoint-down(sm) {
          margin-left: 10px;
        }
      }
    }
  }

  .btn-link--toggle {
    padding-left: 0;
  }

  .custom-control {
    margin-bottom: 5px;
  }
}
