.menu-drop {
  padding: 0;
  width: 100%;
  max-width: 1200px;
  background-color: transparent;

  &__decor {
    position: absolute;
    bottom: 100%;
    left: 187px;
    display: flex;
    height: 70px;
    align-items: center;
    background-color: #fff;

    .header__btn-menu {
      margin: 0;
    }

    .header__link {
      margin: 0 10px;
    }

    &--2 {
      left: 252px;
    }

    &--3 {
      left: 331px;
    }

    &--4 {
      left: 470px;
    }

    &--5 {
      left: 580px;
    }

    &--6 {
      left: 640px;
    }
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 45px;
    width: 24px;
    height: 24px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABIUlEQVQ4T4WTTVKDQBBGv06oMjvjBRxzAuMOXOENdGuNVXgCy5vkBsYKcZ0byCqzFE9AkROwzAIZCwKEzF9YdvV7/fUMQzkPFgDeQFiwlXjHmW/34kdS0gckLdl6+0o5D2TPtEWbo4fbhsq7uCK12JlVidYHfLJYRFQ3npPY4JptBC6JCz4RmCQEJBIIB+s0sYfr9QmsSY7dGqwl6HpzHhQALoeTWCy0YUZB9nw/H43kj3oLBDxdx2JjqB9LLfwNYGr4F4qqoofZ1zY1noEKE+Tvn4fHcYmNBN22kCZp9jLDk3C2TIosCqfjcp/YJOSCu6guSf0W+hM/xD5MVs/AKPHkHe24n9bxXLAlSc5icUO1GeV+Dm+SmiabXmbG/bDr/wecXM03FwH0xAAAAABJRU5ErkJggg==)
                no-repeat center center;
  }

  &__content {
    position: relative;
    margin-top: 70px;
    padding: 30px 50px;
    background-color: #fff;
  }

  &__search {
    margin-bottom: 30px;
    max-width: 860px;
  }

  &__main-link {
    padding-right: 30px;
    font-family: $font-ibm-600;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    text-decoration: none;

    &--toggle {
      display: block;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAABGElEQVQ4T2NkIAT65yswFCY+IKSMEa+CnoUXGBj+6zMw/k9kKE5cgE8tAYMWfGBgYOAHG0DAMPwGdc03YGBiPECMYfgNArkEYth5uLdwuIywQSATeucnMPxnnI/PMOIMIsIw4g3CZti//4YMZYkXwHEBIu7HWDiwMDL+J5RWQPKxSp4eB3jlKkBslv//vnh/vBe+saVlG+PDaMsCBkaGfmIMgalZLaTOUCLnAOby/v31/zMjqxHFBvH8/fX/C8ggmNeIdVGykqfHPj75cpB65v//vvp8vBcG9hqxBoDVoSaDjwz//jugBDZRhuExBB5rBA1CT5BI0Q7TS9hrRKRqwi6C5LP9DAwMAoRKADoVI1Qr2ED+IbKoBQDLeoTHAAbbNQAAAABJRU5ErkJggg==)
        no-repeat right center;

        &[aria-expanded='true'] {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAADCAYAAAB8mEQQAAAAS0lEQVQoU2NkYGBgeBhjuYCRgUEexAaB/wwMDjA2qfR/BoYNjPejrAyYmP6fJ1UzPvWMIMkHMZYHGBkY7AkY/PE/A8MFQpYzAtUAANaTDqMc7XxDAAAAAElFTkSuQmCC);
        }
      }
    }

    &__list-main {
      padding: 0;
      list-style: none;

      > li {
        padding-top: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid $light;
      }
    }

    &__list {
      padding-top: 10px;
      padding-left: 10px;
      list-style: none;
    }

    &__link {
      font-size: 14px;
    }

    .fancybox-close-small {
      display: none;
    }
  }
