h1,
.h1 {
  margin-bottom: 0;
  font-family: $font-ibm-600;
  font-size: 44px;
  line-height: 52px;
  font-weight: 600;

  @include media-breakpoint-down(md) {
    //    font-size: 36px;
    //    line-height: 42px;
    font-size: 40px;
    line-height: 1.2;
  }

  @include media-breakpoint-down(sm) {
    font-size: 26px;
    line-height: 32px;
  }
}

h2,
.h2 {
  margin-bottom: 0;
  font-family: $font-ibm-600;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;

  @include media-breakpoint-down(sm) {
    //    font-size: 20px;
    //    line-height: 28px;
    font-size: 22px;
    line-height: 26px;
  }

  &.h2--md-sm {

    @include media-breakpoint-down(md) {
      font-size: 22px;
      line-height: 26px;
    }
  }
}

h3,
.h3 {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;

  @include media-breakpoint-down(sm) {
    font-size: 17px;
    line-height: 22px;
  }

  &.h3--md-sm {

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 21px;
    }
  }
}

h4,
.h4 {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

h5,
.h5,
.h5 > {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;

  p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
  }
}

h6,
.h6 {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
}

p,
.p {
  font-size: 18px;
  line-height: 24px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 21px;
  }

  &.p--lg {
    font-size: 21px;
    line-height: 32px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.p {

  &--quote {
    display: flex;

    &:before {
      content: '”';
    }

    &:before {
      font-size: 4em;
      font-weight: bold;
      line-height: .5em;
      color: $primary;
    }
  }
}

.font-weight-medium {
  font-weight: 500;
}

ul {

  li {
  }
}

ol {

  li {
  }
}

table {
}

tr {
}

th {
}

td {
}

input {

  &:focus {
    outline: none;
  }
}

textarea {

  &:focus {
    outline: none;
  }
}

select {
}

button {
  padding: 0;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

input[type='submit'] {
  border: 0;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;

  &.img--logo-sm {

    @include media-breakpoint-down(lg) {
      width: 38px;
    }

    @include media-breakpoint-down(sm) {
      width: 28px;
    }
  }
}

.font-clear-sans {
  font-family: $fonts-clear-sans;
}

.font-ibm-600 {
  font-family: $font-ibm-600;
  font-weight: 600;
}
