.audio-samples-card {
  box-shadow: 0 6px 14px rgba(#000, 8%);
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  gap: 8px;

  @include media-breakpoint-down(xs) {
    box-shadow: none;
    padding: 0;
  }
}
.audio-samples-card__more {
  padding: 10px 12px;

  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
  }
}
