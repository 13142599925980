@at-root {
  $primary: #E15C39;
  $gray: rgba(#212529, 0.4);

  .vjs-x-plain-player {
    display: block;
    width: 100%;

    // Скопировано из стилей, встроенных в video-js
    :not(.vjs-visible-text) > .vjs-control-text {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    // Корневой элемент интерфейса плеера
    // [vjs-play-control] [vjs-x-controls-container]
    .vjs-control-bar {
      display: flex;
      flex-flow: row nowrap;
      padding: 0.75rem;
      gap: 0.75rem;
      transition: box-shadow 0.15s ease;

      // Скрывает стандартные элементы интерфейса. Весь нужный интерфейс мы покажем явно.
      & > * {
        display: none;
      }
    }
    &.vjs-has-started {
      .vjs-control-bar {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 8%);
      }
    }

    .vjs-play-control {
      display: block;
      flex: 0 0 auto;
      width: 2.8125rem;
      height: 2.8125rem;
      border-radius: 50%;
      border: 1px solid $primary;
      background: none;
      position: relative;
      transition: background-color ease 0.15s;
    }
    .vjs-icon-placeholder {
      width: 14px;
      height: 17px;
      background: url(../../images/play-orange.svg) center / contain no-repeat;
      position: absolute;
      top: 50%;
      left: calc(50% + 1px);
      transform: translate(-50%, -50%);
    }
    .vjs-play-control:hover {
      background: $primary;

      .vjs-icon-placeholder {
        background-image: url(../../images/play-white.svg);
      }
    }
    &.vjs-has-started {
      .vjs-play-control {
        background: $primary;
      }
      .vjs-icon-placeholder {
        background-image: url(../../images/play-white.svg);
      }
    }
    &.vjs-playing {
      .vjs-play-control {
        background: $primary;
      }
      .vjs-icon-placeholder {
        background-image: url(../../images/pause-white.svg);
        left: calc(50% - 1px);
      }
      .vjs-play-control:hover {
        .vjs-icon-placeholder {
          background-image: url(../../images/pause-white.svg);
        }
      }
    }

    // Элементы справа от кнопки
    // [vjs-x-audio-title]
    // [vjs-x-progress-bar-container]
    .vjs-x-controls-container {
      display: flex;
      flex-flow: column;
      flex: 1 1 0;
      gap: 0.25rem;
    }

    .vjs-x-audio-title {
      display: block;
      font-size: 1rem;
      line-height: 1.25;
      font-weight: 600;
    }

    // Время и ползунок
    // [vjs-current-time] [vjs-progress-control] [vjs-duration]
    .vjs-x-progress-bar-container {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.375rem;
      align-items: center;
    }

    .vjs-progress-control {
      flex: 1 1 0;
      cursor: pointer;
      display: none;
      align-items: center;
      touch-action: none;
    }
    &.vjs-has-started {
      .vjs-progress-control {
        display: flex
      }
    }
    .vjs-progress-holder {
      position: relative;
      cursor: pointer;
      user-select: none;
      flex: auto;
      height: 11px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $gray;
      }
    }
    .vjs-load-progress {
      display: none;
    }
    .vjs-play-progress {
      background-color: $primary;
      position: absolute;
      display: block;
      top: 5px;
      height: 1px;
      margin: 0;
      padding: 0;
      width: 0;

      &:before {
        content: "";
        display: block;
        width: 0.25rem;
        height: calc(0.625rem + 1px);
        background-color: $primary;
        position: absolute;
        top: -0.3125rem;
        right: -0.125em;
        z-index: 1;
      }
    }

    // По-умолчанию выводятся при наведении курсора на ползунок
    .vjs-time-tooltip {
      display: none;
    }

    .vjs-current-time, .vjs-duration {
      display: block;
      flex: 0 0 auto;
      font-size: 1rem;
      line-height: 1.25;
      color: $gray;
    }
    .vjs-current-time {
      display: none;
    }
    &.vjs-has-started {
      .vjs-current-time {
        display: block;
      }
    }

    .vjs-x-error-message {
      display: none;
      color: $gray;
    }
    &.vjs-error {
      background-color: rgba(0, 0, 0, 0.03);
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 20px;

      .vjs-x-error-message {
        display: block;
      }
    }
    .vjs-error-display {
      display: none;
    }
  }
}
