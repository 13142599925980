.after-front {
  position: relative;
  z-index: 5;
  margin-top: -14px;

  @include media-breakpoint-down(md) {
    margin-top: -10px;
  }

  &--has-title {
    margin-top: -76px;

    @include media-breakpoint-down(md) {
      margin-top: -90px;
    }
  }
}
