.my-table {
  width: 100%;
  border-collapse: collapse;
}

.my-table-cell {
  width: 100px;
  height: 40px;
  text-align: center;
  border: 1px solid black;
}
