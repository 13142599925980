.success-popup {
    width: 420px;
    padding: 46px 40px 40px;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.success-popup__icon {
    margin-bottom: 20px;
}
.success-popup__title {
    text-align: center;
    margin-bottom: 20px;
}
.success-popup__message {
    text-align: center;
    margin-bottom: 30px;
}
@include media-breakpoint-down(sm) {
    .success-popup {
        width: 100%;
        padding: 30px 30px 40px;
    }
    .success-popup__icon {
        margin-bottom: 15px;
    }
    .success-popup__title {
        margin-bottom: 15px;
    }
    .success-popup__message {
        margin-bottom: 30px;
    }
}
