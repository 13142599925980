.team {
  height: 550px;
  background-color: $light;

  @include media-breakpoint-down(md) {
    height: 450px;
  }

  @include media-breakpoint-down(xs) {
    height: auto;
  }

  &__photo {
    position: absolute;
    bottom: 0;
    left: 0;

    @include media-breakpoint-down(xs) {
      position: static;
      margin-bottom: 20px;
      margin-left: -10px;
    }

    img {

      @include media-breakpoint-down(md) {
        width: 512px;
      }

      @include media-breakpoint-down(sm) {
        width: 70%;
      }

      @include media-breakpoint-down(xs) {
        width: auto;
        max-width: 100%;
      }
    }
  }

  &__title {
    margin: 77px 0 123px;

    @include media-breakpoint-down(md) {
      margin: 52px 0 65px;
      font-size: 22px;
    }

    @include media-breakpoint-down(xs) {
      margin: 34px 0 43px;
    }
  }

  &__name {
    margin-bottom: 10px;
  }

  &__role {
    margin-bottom: 0;

    @include media-breakpoint-down(xs) {
      margin-bottom: 30px;
    }
  }
}
