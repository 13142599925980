.counter {
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 6px 14px rgba(0, 0, 0, .08);

  @include media-breakpoint-down(md) {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 0;
    padding-bottom: 20px;
  }

  &__number {
    margin: 10px;
    display: flex;
    align-items: center;
    font-size: 85px;
    line-height: 1;
    font-weight: 300;
    color: $secondary;

    @include media-breakpoint-down(lg) {
      font-size: 75px;
    }
  }

  &__text {
    padding: 0 10px;
    text-align: center;
  }
}
