.form-block {
  padding: 46px 60px 40px;
  background: #fff url(../../images/form-block-bg.png) 0 0 repeat-y;
  border-radius: 4px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, .08);

  @include media-breakpoint-down(md) {
    padding-top: 20px;
    padding-right: 15px;
    padding-left: 30px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 11px;
    padding-right: 10px;
    padding-left: 35px;
  }

  h5,
  .h5 {

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  .btn.btn-primary {
    width: 128px;
    height: 45px;
    border-radius: 2px;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  &--primary {
    background-image: url(../../images/form-block-orange.png);
  }
}
