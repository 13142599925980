.project-cost-table {
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.5rem;

  th.project-cost-table__th--muted {
    color: rgba(33, 37, 41, 0.40);
    font-weight: normal;
  }

  td, th {
    padding: 1rem;
  }
}

.project-cost-table__item-description {
  color: rgba(33, 37, 41, 0.40);
  font-size: 1rem;
  line-height: 1.3125rem;
  font-weight: normal;
}

@include media-breakpoint-up(md) {
  .project-cost-table {
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);

    tr {
      --td-bg-base: white;
      --td-bg-odd: #f8f8f9;
    }
    tr.project-cost-table__tr--total {
      font-weight: bold;
    }
    td:nth-child(even), th:nth-child(even) {
      --td-bg-base: #f6f6f6;
      --td-bg-odd: #eff0f1;
    }

    td, th {
      background: var(--td-bg-base);
    }
    tr:nth-child(odd) {
      td {
        background: var(--td-bg-odd);
      }
    }
  }

  .project-cost-table__td-price-label {
    display: none;
  }

  .project-cost-table__item-description {
    color: rgba(33, 37, 41, 0.40);
    font-size: 1rem;
    line-height: 1.3125rem;
    font-weight: normal;
  }
}

@include media-breakpoint-down(sm) {
  .project-cost-table {
    display: block;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-flow: column;
      gap: 0.625rem;
    }

    tr {
      display: flex;
      flex-flow: column;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
      border-radius: 2px;
    }
    tr.project-cost-table__tr--total {
      .project-cost-table__td-price-value {
        font-weight: bold;
      }
    }

    td, th {
      display: block;
    }
    td:first-child {
      font-weight: bold;
      border-bottom: 1px solid rgba(33, 37, 41, 0.10);
      margin-bottom: 1rem;
    }
    td:not(:first-child) {
      padding: 0 1rem 1rem;
    }
    .project-cost-table__td--blank {
      display: none;
    }
    .project-cost-table__td--price:not(.project-cost-table__td--blank) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}
