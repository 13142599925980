.map {
  position: relative;
  height: 365px;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  &__place {
    height: 365px;
  }

  &__text {
    position: absolute;
    z-index: 5;
    top: 27px;
    left: 140px;
    padding: 16px 25px 40px 20px;
    width: 100%;
    max-width: 590px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 6px 14px rgba(0, 0, 0, .08);

    @include media-breakpoint-down(md) {
      left: 10px;
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      top: auto;
      left: 0;
      padding: 15px 16px 20px;
    }
  }

  &__text p {
    font-size: 16px;
    line-height: 21px;
    color: #000;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 19px;
    }
  }
}
