.map-contacts {

  .tab-content > .tab-pane {
    display: block;
    height: 0;
  }

  .tab-content > .active {
    height: auto;
  }

  .slick-arrow {

    @include media-breakpoint-down(sm) {
      top: 20px;
    }
  }

  &__map-wrap {
    margin-bottom: 33px;
    overflow: hidden;
    height: 575px;

    @include media-breakpoint-down(md) {
      height: 416px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 18px;
      height: 400px;
    }
  }

  &__icon {
    text-align: center;
  }

  &__img {
    width: auto;
    height: 30px;

    @include media-breakpoint-down(md) {
      height: 25px;
    }
  }

  &__desc {
    padding-left: 15px;
    font-size: 18px;
    line-height: 24px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__table {
    margin-bottom: 50px;

    td {
      padding-bottom: 9px;
    }
  }

  [class*='ymaps-2'][class*='-map'] {
    height: 100% !important;
  }
}
