.categories {

  &__link {
    position: relative;
    padding: 13px 20px;
    display: flex;
    width: 100%;
    min-height: 75px;
    font-weight: 700;
    text-decoration: none;
    background: $white;
    border-radius: 2px;
    box-shadow: 0 6px 14px rgba(0, 0, 0, .08);

    &:after {
      position: absolute;
      right: 23px;
      bottom: 10px;
      display: block;
      width: 19px;
      height: 19px;
      content: '';
      background: url('../../images/slick-arrow.svg') no-repeat center transparent;
    }

    @include transition();

    &:hover {
      color: $white;
      background: $primary;
      box-shadow: 0 6px 14px rgba(225, 92, 57, .7);

      &:after {
        background: url('../../images/slick-hover.svg') no-repeat center transparent;
      }
    }

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
