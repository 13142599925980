.btn {
  border-radius: 2px;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &--call-me {
    padding-top: 0;
    padding-right: 19px;
    padding-left: 49px;
    padding-bottom: 0;
    height: 40px;
    font-size: 14px;
    line-height: 38px;
    text-decoration: none;
    color: $primary;
    background: url(../images/call-me-color.svg) no-repeat left center;
    border: 1px solid;
    border-radius: 20px;

    &:hover {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }

  &--white {
    padding: 0 25px;
    height: 45px;
    line-height: 45px;
    text-decoration: none;
    color: $primary;
    background-color: #fff;
    border: none;

    &:hover {
      color: #fff;
      background-color: $dark;
    }
  }

  &--arrow-right {
    --icon-orange: url(../images/slick-arrow-orange.svg);
    --icon-white: url(../images/slick-hover.svg);
    --icon: var(--icon-orange);

    &::after {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      background: var(--icon) center / contain no-repeat;
      margin-left: 10px;
    }
  }
}

.btn-primary {
  padding: 0 25px;
  height: 45px;
  line-height: 43px;
  white-space: nowrap;
  text-decoration: none;

  @include media-breakpoint-down(sm) {
    padding-right: 15px;
    padding-left: 15px;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $primary;
    border-color: $primary;
    box-shadow: 0 6px 14px rgba(225, 92, 57, .7);
  }
}

.btn-outline-primary {
  padding: 0 25px;
  height: 45px;
  line-height: 43px;
  white-space: nowrap;
  text-decoration: none;

  @include media-breakpoint-down(sm) {
    padding-right: 15px;
    padding-left: 15px;
  }

  &.btn--arrow-right:hover {
    --icon: var(--icon-white);
  }
}

.btn-link {

  &--toggle {
    position: relative;
    padding-top: 20px;
    padding-right: 65px;
    padding-left: 15px;
    padding-bottom: 20px;
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    text-align: left;
    text-decoration: none;

    @include media-breakpoint-down(xs) {
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 14px;
      line-height: 21px;
    }

    &:after {
      position: absolute;
      top: 14px;
      right: 25px;
      display: block;
      width: 36px;
      height: 36px;
      content: '';
      background: url(../images/arrow-down.png) no-repeat center center;
      border: 1px solid $light;
      border-radius: 50%;
      transform-origin: center center;

      @include transition;

      @include media-breakpoint-down(xs) {
        top: 12px;
        right: 20px;
        width: 28px;
        height: 28px;
        background-size: 15px;
      }
    }

    &[aria-expanded='true']:after {
      transform: rotate(-180deg);
    }
  }

  &--calc {
    padding-top: 0;
    padding-right: 0;
    padding-left: 30px;
    padding-bottom: 0;
    height: 25px;
    font-size: 14px;
    line-height: 25px;
    background: url(../images/calc-color.png) no-repeat left center;
  }

  &--arrow {
    padding: 0 35px 0 0;
    line-height: 20px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAABCUlEQVQ4T5XUsU4CQRAG4H9CBRQk0lhCdXZaSoVWZ0lhoznCmRhbrWih9q7wDVZew4pHoKM119poQkHCuWv2cmc0gZm5TbbY3cm3k53JErjxbM7QoAm+3QLTuxUXSiyULlaAOwXwCesuOUyAzAtAj+VlLMZDXkjNK0ATCZMhJaaDFJgeErB6EINRFg1GAKrKsN1QHd73w5O3Tu/Yr9t2l998rCN6jwZLAoYq4U/QVXCNdbNb7Bzl2w1l4/PYOorrQA/9MKgyatldfuszqgMUsf/76gvWXfiOrwcdQLyvhxhEDwmIDlIgMpSYOYhmZUF+H3Zfgfg3SswSRL7HWETOKDUjODwVU/ghfwAFfIt9zoBahAAAAABJRU5ErkJggg==)
                no-repeat right center;
  }

  &--arrow-short {

    @include media-breakpoint-down(xs) {
      padding-right: 0;
      background: transparent;
    }
  }
}

.form-control {
  height: calc(1.5em + .75em + 4px);
  font-size: 16px;
  color: #000;
  border: 1px solid $gray;
  border-radius: 2px;

  &:focus {
    color: #495057;
    border-color: #000;
    box-shadow: none;
  }

  &::placeholder {
    opacity: 1;
    font-size: 16px;
    color: #8d8d8d;
  }

  &--search-white {
    padding-right: 35px;
    padding-left: 16px;
    font-size: 14px;
    color: rgba(255, 255, 255, .6);
    background-color: #277886;

    &::placeholder {
      font-size: 14px;
      color: rgba(255, 255, 255, .6);

      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }

    &:focus {
      color: rgba(255, 255, 255, .6);
      background-color: #277886;
    }

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }

  &--search {
    padding-right: 40px;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-right: 0;
  background: none;
  border-color: $primary !important;
  box-shadow: none !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  padding-right: 0;
  background: none;
  border-color: $gray !important;
  box-shadow: none !important;
}

.invalid-feedback {
  font-size: 12px;
  color: $primary;
}

.custom-control {
  padding-left: 40px;
  min-height: 30px;

  .invalid-feedback {
    margin-top: 10px;
    margin-left: -40px;
  }
}

.custom-control-label {
  color: #000 !important;
  box-shadow: none !important;
  cursor: pointer;
}

.custom-control-label:before {
  position: absolute;
  top: -2px;
  left: -40px;
  width: 30px;
  height: 30px;
  background-color: #fff !important;
  border: 1px solid $gray !important;
  box-shadow: none !important;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 2px;
}

.custom-control-input:checked ~ .custom-control-label:before {
  background-color: #fff;
  border-color: $gray;
}

.custom-control-label:after {
  position: absolute;
  top: -2px;
  left: -40px;
  width: 30px;
  height: 30px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background: transparent url('../images/checkbox-checked.svg') center center no-repeat;
  background-size: 18px 16px;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: $gray;
  box-shadow: none;
}

.custom-file {
  position: relative;
  height: 38px;

  &__delete {
    position: absolute;
    z-index: 2;
    top: 6px;
    right: 0;
    margin-left: 14px;
    display: none;
    font-size: 18px;
    text-decoration: underline;
    color: #000000;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &__has-file {

    .custom-file__delete {
      display: block !important;
    }

    .custom-file-label {

      &:before {
        margin-right: 5px;
        display: block;
        width: 17px;
        height: 22px;
        content: '';
        background: transparent url(../images/file.svg) 0 0 no-repeat;
      }

      &__file-name {
        overflow: hidden;
        width: calc(100% - 101px);
        text-overflow: ellipsis;
      }
    }
  }
}

.custom-file-label {
  margin: 0;
  padding: 0;
  display: flex;
  height: 38px;
  align-items: center;
  font-size: 18px;
  text-decoration: underline;
  color: #000000;
  border: 0 none;
  box-shadow: none !important;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }

  &:hover {
    text-decoration: none;
  }

  &:after {
    display: none;
  }
}

.nav-links {
  display: inline-flex;
  border-bottom: 3px solid $light;

  .nav-item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  .nav-link {
    position: relative;
    padding: 0;
    padding-bottom: 9px;
    font-size: 14px;
    line-height: 19px;
    text-decoration: none;

    @include transition;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $primary;
    }

    &.active {

      &:after {
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        width: 100%;
        height: 3px;
        content: '';
        background-color: $primary;
      }
    }
  }
}

.mw-200 {
  max-width: 200px;
}

textarea {

  &.form-control {
    height: 80px;
    resize: none;

    @include media-breakpoint-down(md) {
      height: 70px;
    }
  }
}

.asteriskField {
  display: none;
}

.dropdown-item {
  text-decoration: none;
}
