.mail-rev {

  &__title {
    margin-bottom: 16px;
  }

  &__loop {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    color: $primary;
    background-color: $white;
    border: 1px solid $light;
    border-radius: 50%;

    @include transition();
  }

  &__link {
    position: relative;
    padding-top: 21px;
    padding-bottom: 37px;
    display: flex;
    justify-content: center;
    border: 1px solid $light;

    @include media-breakpoint-down(sm) {
      padding-top: 20px;
      padding-bottom: 15px;
    }

    svg {
      display: block;
    }

    &:hover {

      .mail-rev__loop {
        color: $white;
        background-color: $primary;
        border: none;
      }
    }
  }

  &__prev {
    max-width: 300px;
  }
}
