.brand {
  position: relative;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid $light;
  border-radius: 2px;

  &__img {
    max-width: 98px;
  }
}
