.icon-link {
  &::after {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: var(--icon) center / contain no-repeat;
    position: relative;
    top: 0.125em;
    margin-left: 0.375em;
  }
}
.icon-link--arrow-right {
  --icon: url(../../images/slick-arrow.svg);
}
