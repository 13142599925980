@import 'suggestions-jquery/dist/css/suggestions';

.suggestions-suggestions {
    // Поднимаем выше модальных окон.
    z-index: 99999;
    // min-width не дает задать ширину, равную ширине инпута, в js
    min-width: 0;
}

.suggestions-promo {
    // Скрываем логотип
    display: none !important;
}
