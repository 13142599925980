.front {
  position: relative;
  z-index: 1;
  margin-top: -70px;
  margin-bottom: 90px;
  background-color: #fff;

  @include media-breakpoint-down(lg) {
    margin-bottom: 70px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__row {
    padding-top: 45px;
    overflow: hidden;
    height: 680px;
    align-items: center;

    @include media-breakpoint-down(lg) {
      height: 570px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 40px;
      padding-bottom: 40px;
      height: auto;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 17px;
    }
  }

  &__bg {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-size: cover;
  }

  &__content {
    padding-left: 110px;
    color: #fff;

    @include media-breakpoint-down(lg) {
      padding-left: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      color: #000;
    }
  }

  &__text {

    p {
      margin-bottom: 25px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 25px;
        font-size: 19px;
        line-height: 28px;
      }
    }
  }

  &__title {
    font-family: $font-ibm-600;
    font-size: 44px;
    line-height: 52px;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      font-size: 40px;
      line-height: 48px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 26px;
      line-height: 32px;
    }
  }
}
